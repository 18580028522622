import React from "react"
import styled from "@emotion/styled"

const navItems = [
  { text: "Docs", href: "/docs/" },
  { text: "GitHub", href: "https://github.com/yunabe" },
  { text: "Facebook", href: "https://www.facebook.com/yunabe" },
]

/*
const  {{ range .Site.Data.themeconfig.nav }}
<div {{if hasPrefix $.URL .link}}class="ac"{{end}}><a href={{ .link }}>{{ .text }}</a></div>
{{ end }}
*/

const Title = styled.div`
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  font-weight: bold;
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  right: 0;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
  padding: 0rem 1rem;
  line-height: 2.2rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  nav {
    display: flex;
    > div {
      font-size: 0.875rem;
      color: inherit;
      font-weight: 500;
      display: inline-block;
      padding: 0 0.5rem;
      margin: 0 0.25rem;
      border-bottom: 3px solid transparent;

      :hover,
      .hlt {
        border-bottom: 3px solid var(--accent-color);
      }
    }
    > div.hlt {
      border-bottom: 3px solid var(--accent-color);
    }
  }

  a {
    color: inherit;
    box-sizing: border-box;
  }
`

export default ({ slug }) => {
  const items = []
  for (const e of navItems) {
    let cls = null
    if (slug.startsWith(e.href)) {
      cls = "hlt"
    }
    items.push(
      <div key={e.href} className={cls}>
        <a href={e.href}>{e.text}</a>
      </div>
    )
  }
  return (
    <Header>
      <Title>
        <a href="/">yunabe.jp</a>
      </Title>
      <nav>{items}</nav>
    </Header>
  )
}
