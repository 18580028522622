import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import AdSense from "react-adsense"

import Nav from "./Nav"
import KatexCss from "./KatexCss"

const Content = styled.div`
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  p,
  ol,
  ul {
    line-height: 1.7;
  }

  a {
    color: var(--accent-color);
  }

  *:not(pre) > code {
    font-size: 0.875em;
    background-color: rgba(27, 31, 35, 0.05);
    padding: 0.25rem 0.5rem;
    margin: 0;
    border-radius: 3px;
  }

  h1 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.35rem;
  }

  h2 {
    font-size: 1.65rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #eaecef;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 3rem;
    margin-top: -2rem;
  }

  img {
    max-width: 100%;
  }

  pre {
    line-height: 1.4;
    padding: 1.25rem 1.5rem;
    margin: 0.85rem 0;
    border-radius: 6px;
    overflow: auto;
    font-size: 0.875rem;
    @media (max-width: 419px) {
      border-radius: 0px;
      margin: 0.85rem -1.5rem;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New,
      monospace;
  }
  /* https://www.gatsbyjs.org/packages/gatsby-remark-prismjs/#line-highlighting */
  .gatsby-highlight-code-line {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    background-color: #444;
    display: block;
  }

  /* tableOfContents injects p under li */
  li > p {
    margin: 0;
  }

  blockquote {
    font-size: 0.9rem;
    color: #888;
    border-left: 0.5rem solid #dfe2e5;
    margin: 0.5rem 0;
    padding: 0.25rem 0 0.25rem 1rem;
    > p {
      margin: 0;
    }
  }
`

function insertAdBefore(parent, { ref = null, format = "auto" }) {
  const inc = document.createElement("ins")
  inc.className = "adsbygoogle"
  inc.style.display = "block"
  inc.setAttribute("data-ad-client", "ca-pub-5305161629251914")
  inc.setAttribute("data-ad-slot", "7099729504")
  inc.setAttribute("data-ad-format", format)
  inc.setAttribute("data-full-width-responsive", "true")
  parent.insertBefore(inc, ref)
  // We have to execute this for every ad.
  ;(window.adsbygoogle = window.adsbygoogle || []).push({})
}

const SideBar = () => {
  // Don't set left and right to position:fixed.
  // https://stackoverflow.com/questions/6794000/fixed-position-but-relative-to-container
  // "overflow-y: auto" enables scrolling in fixed element.
  return (
    <div
      css={css`
        width: 300px;
        @media (max-width: 1087px) {
          width: 160px;
        }
        @media (max-width: 948px) {
          display: none;
        }
      `}
    >
      <div
        css={css`
          margin-top: 3rem;
          position: fixed;
          top: 0;
          bottom: 0;
          width: 300px;
          overflow-y: auto;
          @media (max-width: 1087px) {
            width: 160px;
          }
        `}
      >
        <div
          ref={elm => {
            if (elm == null) {
              return
            }
            requestAnimationFrame(() => {
              if (elm.querySelector(".adsbygoogle")) {
                // This can happen when ContentBody is rendered with the same html.
                return
              }
              if (elm.getBoundingClientRect().width === 0) {
                // display:none
                return
              }
              insertAdBefore(elm, { format: "vertical" })
            })
          }}
        ></div>
      </div>
    </div>
  )
}

const ContentFooter = ({ post }) => {
  const { lastmod } = post.frontmatter
  if (!lastmod) {
    return null
  }
  return (
    <div
      css={css`
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-end;
        font-size: 0.875rem;
        color: #888;
      `}
    >
      <div>
        <b>最終更新:</b> {new Date(lastmod).toLocaleDateString("ja")}
      </div>
    </div>
  )
}

function mayInjectToc(post) {
  const { html } = post
  if (!post.frontmatter.enable_toc) {
    return html
  }
  const match = /<h2[>\s]/.exec(post.html)
  if (!match) {
    return html
  }
  // TODO(yunabe): File a bug about '.html/#' bug.
  return [
    post.html.substr(0, match.index),
    "<h3>目次</h3>",
    post.tableOfContents.replace(/\.html\/#/g, ".html#"),
    post.html.substr(match.index),
  ].join("")
}

const ContentBody = ({ post }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: mayInjectToc(post) }}
      ref={elm => {
        if (elm == null) {
          return
        }
        requestAnimationFrame(() => {
          if (elm.querySelector(".adsbygoogle")) {
            // This can happen when ContentBody is rendered with the same html.
            return
          }
          const width = elm.getBoundingClientRect().width
          let interval = (500 * 1000) / width
          if (interval < window.innerHeight) {
            interval = window.innerHeight
          }
          const headers = elm.querySelectorAll("h2,h3")
          let prev = Number.MIN_VALUE
          for (let i = 0; i < headers.length; i++) {
            const header = headers[i]
            const top = header.getBoundingClientRect().y
            if (top < window.innerHeight || top < prev + interval) {
              continue
            }
            prev = top
            insertAdBefore(header.parentNode, { ref: header })
          }
        })
      }}
    />
  )
}

const HelmetHeader = ({ post, site }) => {
  return (
    <Helmet>
      <title>{post.frontmatter.title + " | " + site.siteMetadata.title}</title>
    </Helmet>
  )
}

export default ({ data }) => {
  const post = data.markdownRemark
  const site = data.site
  let katexCss = null
  if (/class=["']?katex/.test(post.html)) {
    katexCss = KatexCss
  }
  return (
    <div css={katexCss}>
      <HelmetHeader post={post} site={site} />
      <Nav slug={post.fields.slug} />
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: stretch;
        `}
      >
        <main
          css={css`
            width: 100%;
            max-width: 740px;
          `}
        >
          <Content>
            <h1>{post.frontmatter.title}</h1>
            <ContentBody post={post} />
            <ContentFooter post={post} />
            <AdSense.Google
              style={{ display: "block" }}
              client="ca-pub-5305161629251914"
              slot="7099729504"
              format="auto"
              responsive="true"
            />
          </Content>
        </main>
        <SideBar />
      </div>
    </div>
  )
}
